@import "breakpoint"; // import scss file to get $breakpoint variable (max-width for mobiles)
@import "scss-config"; // import primary colors

.contacts{
    background-color: $swift-coal-2;
    &-top{
        margin:16px auto;
        padding-bottom: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: $breakpoint) { // Responsive  : mobile screen
            flex-direction: column;
            align-items: left;
        }
        &-left{
            p{
                font-size:18px;
                color:$swift-lime-1;
                margin:8px;
                
            }
           
        }
        &-right{
            text-align: right;
            @media (max-width: $breakpoint) { // Responsive  : mobile screen
                margin-top:16px;
                text-align: left;
            }
            p{
                font-size:18px;
                color:$swift-lime-1;
                margin:8px;
                
            }
           
        }
    }
}