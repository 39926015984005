@import "breakpoint"; // import scss file to get $breakpoint variable (max-width for mobiles)
@import "scss-config"; // import primary colors

.realisations{
    background-color: $swift-lime-1;

    &-content{
        margin-top:32px;
    }

    &-selector{
        width:100%;
        background-color: $swift-turquoise;
        border-radius: 10px;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding:8px 16px;

        @media (max-width: $breakpoint) { // Responsive  : mobile screen
            flex-direction: column;
        }

        p{
            font-size:16px;
            line-height:24px;
            text-transform: capitalize;
        }
        
        &-categorie,&-categorie-selected{
            
            padding:4px 16px;
            text-align: center;
            cursor: pointer;
            @media (max-width: $breakpoint) { // Responsive  : mobile screen
                width:100%;
                margin-top:4px;
            }
            
        }
        &-categorie{
            color:$swift-lime-1;
            border-bottom: 1px solid $swift-lime-1;
            &:hover{
                border-bottom: 3px solid $swift-lime-1;
                padding-bottom:1px;
            }

        }
        &-categorie-selected{
            background-color:$swift-lime-1;
            border-radius: 10px;
            font-weight: 700;
            color:$swift-coal-2;

        }
    }

    &-list{
        padding:8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: $breakpoint) { // Responsive  : mobile screen
            justify-content: space-around;
        }
    }

    &-seemore{
        width:100%;
        margin-top:16px;
        padding:8px;
        border-top:2px solid $swift-grass;
        text-align: center;
        p{
            display:block;
            padding:8px;
            font-size: 20px;
            color:$swift-grass;
            cursor: pointer;
            &:hover{
                color:$swift-coal-2;
            }
        }
    }

    &-dialog{
        &-content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            max-width: 880px;
            max-height: 90vh;
            overflow-y: auto;
            background-color: white;
            border-radius: 4px;
            @media (max-width: $breakpoint) { // Responsive  : mobile screen
                width: 90%;
            }

            &-focusimage{
              position: absolute;
              top:0;
              left:0;
              z-index: 10;
              width:100%;
              height:100%;
              background-color: rgba(0,0,0,0.5);
              display: flex;
              align-items: center;
              img{
                width:100%;
                object-fit: cover;
                
              }
            }
        }
        
        &-top{
            width:100%;
            position: relative;
            img{
                width:calc(100% - 8px);
                height:200px;
                margin:4px;
                object-fit:cover;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
            }
            span{
                position:absolute;
                width:36px;
                height:36px;
                background-color: white;
                border-radius: 50%;
                top:16px;
                right:16px;
                color:$swift-turquoise;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                filter: drop-shadow(3px 3px 2px rgba(0,0,0,0.15));
                &:hover{
                    color:$swift-coal-2;
                }
            }
            &-fondu{
              position:absolute;
              width:100%;
              top:0px;
              height:50%;
             // background: linear-gradient(#e66465, #9198e5);
              background: linear-gradient(rgba(0,0,0,0.35),rgba(0,0,0,0));
            }
        }
        &-middle{
            margin:16px auto;
            padding:16px;
            border-top:2px solid $swift-grass;
            width: calc(100% - 8px);
            text-align: center;
            h4{
                font-family:"Chakra Petch";
                font-size:24px;
                color:$swift-grass;
            }
            h5{
                font-size:18px;
                font-weight: 400;
                margin-bottom:16px;
            }
            &-infos{
                width:100%;
                @media (max-width: $breakpoint) { // Responsive  : mobile screen
                    width: 95%;
                }
                &-1{
                    font-weight: 600;
                }
                div{
                    border-left:2px solid $swift-coal-1;
                    margin-top:16px;
                    padding:8px;
                    text-align: left;
                }
                p{
                    margin-top:8px;
                    color:$swift-coal-2;
                    span{
                        text-decoration: underline;
                    }
                    
                }
                a{
                    span{
                        color:black;
                        font-weight: 600;
                        overflow-wrap: break-word;
                    }
                }
            }
        }

        &-bottom{
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            button{
                margin-top:16px;
                color:$swift-grass;
            }
            &-expand{
                background-color: $swift-grass !important;
                color: $swift-lime-1 !important;
                //border:1px solid $swift-grass !important;
                width: calc(100% - 8px) !important;
                margin:8px auto !important;
                display: flex;
                //flex-direction: column;
                &:hover{
                    background-color: $swift-coal-1 !important;
                    color:$swift-lime-2 !important;
                }
            }
            &-photos{
                display: flex;
                flex-direction: column;
                justify-content: baseline;
                align-items: center;
                background-color: $swift-coal-2;
                padding : 8px;
                img{
                    width:100%;
                    /* height:100px;
                    object-fit: cover; */
                    margin: 16px 4px;
                    cursor: pointer;
                    border:2px solid $swift-coal-1;
                    border-radius: 4px;
                    
                    @media (max-width: $breakpoint) { // Responsive  : mobile screen
                        width: 90%;
                    }
                }
            }
        }
    }
}

.card-realisation{
    width:350px;
    height:350px;
    border-radius: 4px;
    margin:16px 8px;
    background-color: white;
    filter: drop-shadow(2px 5px 2px rgba(0,0,0,0.15));

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-top{
        height:160px;
        img{
            width:100%;
            height:100%;
            object-fit: cover;
            border-top-left-radius:4px;
            border-top-right-radius:4px;
        }
    }
    &-middle{
        height: 100%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        padding:8px;
        &-start{
            h3{
                font-weight: 700;
                font-size:20px;
                font-family: "Chakra Petch";
                text-align: center;
                color:$swift-coal-2;
            }
        }

        &-end{
            p{
                margin-bottom:2px;
            }
        }
        &-categorie{
            width:100%;
            text-align: center;
            border-top: 1px solid $swift-turquoise;
            padding:4px;
            p{
                color:$swift-grass;
                font-family: "Chakra Petch";
                font-size:16px;
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }
    }

    &-description{
        color:$swift-coal-2;
        font-weight: 500;
    }
    &-tags{
        color:$swift-coal-1;
    }

    &-bottom{
        padding:4px;
        button{
            background-color: $swift-grass !important;
            font-family: "Rajdhani" !important;
           
        }
    }

    .categorie-couleur-1{
        color:$swift-grass;
    }
    .categorie-couleur-2{
        color:#7458e8;
    }
    .categorie-couleur-3{
        color: rgb(197, 105, 0);;
    }
}