@import "breakpoint"; // import scss file to get $breakpoint variable (max-width for mobiles)
@import "scss-config"; // import primary colors

.competences{
    background-color: $swift-turquoise;
    &-content{
        padding:32px 16px;
        li{
            margin-top:8px;
        }
        @media (max-width: $breakpoint) { // Responsive  : mobile screen
            padding:16px 8px;
        }
        &-item{
            &-header{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width:100%;
                @media (max-width: $breakpoint) { // Responsive  : mobile screen
                    flex-wrap: wrap;
                h3{
                    font-size:16px;
                    width:100%;
                  }
                }
                h3{
                    font-size:18px;
                    font-weight: 500;
                }
            }
            &-realisations{
                padding:8px;
                margin-top:8px;
                border:1px solid $swift-lime-1;
                border-top:2px solid $swift-grass;
                width:90%;
                margin:auto;
                
                &-titre{
                    text-transform: uppercase;
                    font-size:16px;
                    color:$swift-lime-1;
                    margin-bottom:8px;
                    cursor:pointer;
                    display:flex;
                    align-items: center;
                    background-color: $swift-grass;
                    border-radius:4px;
                    padding:4px 8px;
                    @media (max-width: $breakpoint) { // Responsive  : mobile screen
                        flex-direction: column;
                        span{
                            margin-left:4px;
                            font-size: 14px;
                        }
                    }
                    span{
                        color:white;
                        display: flex;
                        align-items: center;
                        margin-left:16px;
                    }
                    &:hover{
                        color:$swift-lime-1;
                        background-color: $swift-coal-2;
                    }
                }
                &-ligne{
                    width:100%;
                    background-color: white;
                    color:$swift-coal-2;
                    padding:4px 8px;
                    margin-bottom:8px;
                    border-radius:4px;
                    cursor: pointer;
                    border-bottom: 1px solid $swift-lime-2;
                    &:hover{
                        //background-color: $swift-lime-2;
                        background-color: $swift-lime-1;
                    }
                    p{
                        & :nth-child(1){
                            color:black;
                            font-weight: 500;
                        }
                    }
                }
            }
            &-experience{
                &-ligne{
                    width:100%;
                    background-color: $swift-coal-1;
                    color:$swift-lime-1;
                    padding:4px 8px;
                    margin-bottom:8px;
                    border-radius:4px;
                    border-bottom: 1px solid $swift-lime-2;
                    span{
                        margin-right:6px;
                        &:nth-child(1){
                            color:$swift-lime-2 !important;
                        }
                        &:nth-child(7){
                            color:$swift-freeze !important;
                        }
                    }
                    
                }
            }
        }
    
    }

    &-soft{
        background-color: $swift-coal-2;
        &-top{
            h3{
                font-family: "Chakra Petch";
                font-size:24px;
                color:$swift-freeze;
                text-align: center;
                font-weight: 300;
                margin-bottom: 16px;
            }
            p{
                color:$swift-lime-1;
                text-align: justify;
            }
            &-list{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top:16px;
                
            }
            &-skill{
                border-bottom:1px solid $swift-lime-1;
                padding:8px 32px;
                text-align: center;
                width: 47%;
                margin:8px;
                cursor: pointer;
                @media (max-width: $breakpoint) { // Responsive  : mobile screen
                    width: 98%;
                    padding:8px;
                }
                &:hover{
                    border-color:$swift-lime-2;
                }
                h4{
                    text-transform: uppercase;
                    color:$swift-lime-1;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span{
                        margin-left:8px;
                    }
                }
                &-selected{
                    border-bottom:2px solid $swift-lime-2;
                    //padding-bottom:6;
                    font-weight: 700;
                    h4{
                        color:$swift-lime-2 ;
                    }
                }
            }

        }

        &-bottom{
            &-titre{
                width:100%;
                text-align: center;
                background-color: $swift-lime-2;
                border-radius: 6px;
                padding:8px;
                margin-top:32px;
                position: relative;
                p{
                    color:$swift-coal-2;
                    font-size:18px;
                    font-weight: 500;
                }
            }

            &-content{
                background-color: $swift-lime-1;
                margin-top:4px;
                border-radius: 4px;
                padding:8px;
            }
            ul{
                color:$swift-coal-2;
            }

            &-texte{
                color:$swift-coal-2;
            }
            &-barre{
                width:50%;
                height:2px;
                display: block;
                background-color: $swift-grass;
            }
            &-exemples{
                padding:16px;
            }
        }
        &-close-icon{
            cursor:pointer;
            position:absolute;
            top:10px;
            right:16px;
        }
    }

}