@import "breakpoint"; // import scss file to get $breakpoint variable (max-width for mobiles)
@import "scss-config"; // import primary colors

.intro{
    
    background-color: $swift-lime-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:16px;

    &-top{
        padding:16px;
        position:relative;
        &-underline{
            position:absolute;
            right:0px;
            top:50px;
            height:2px;
            width:250px;
            background-color: $swift-lime-1;
        }
       h1{
           font-family: "Chakra Petch";
           font-weight: 700;
           font-size: 32px;
           color:$swift-coal-2;
           text-align: center;
           line-height: 32px;
           @media (max-width: $breakpoint) { // Responsive  : mobile screen
            font-size: 22px;
        }

       }
       &-txt1{
        font-family: "Rajdhani";
        font-weight: 400;
        font-size: 24px;
        margin-right:8px;
        font-size: 32px;
        @media (max-width: $breakpoint) { // Responsive  : mobile screen
            font-size: 20px;
        }
       }
       &-txt2{
        font-family: "Rajdhani";
        font-size: 24px;
        color:$swift-grass;
        @media (max-width: $breakpoint) { // Responsive  : mobile screen
            font-size: 20px;
            font-weight: 500;
        }
        
       }
    }
    &-middle{
        img{
            border-radius:50%;
            height: 220px;
        }
    }
    &-bottom{
        margin:24px;
        width:80%;
        display:flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        @media (max-width: $breakpoint) { // Responsive  : mobile screen
            width:100%;
        }
        img{
            max-height:36px;
            @media (max-width: $breakpoint) { // Responsive  : mobile screen
                max-height:26px;
            }
        }
    }

}