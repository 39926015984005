@import "breakpoint"; // import scss file to get $breakpoint variable (max-width for mobiles)
@import "scss-config"; // import primary colors

.top-nav{

    &-left{
            display:flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        a{
            border:none !important;
        }
     }
   
     &-right{
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        }
        
    background-color: $swift-lime-1;
   /*  position: sticky;
    top:0px;
    z-index:100; */
    max-height:60px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:16px;
    border-bottom: 1px solid $swift-coal-1;
    a{
        font-size: 20px;
        color:$swift-coal-2;
        margin-right:16px;
        padding-right:16px;
        display:block;
        border-right: 2px solid $swift-grass;
        text-transform: capitalize;
        &:hover{
            color:$swift-turquoise;
        }
        @media (max-width: $breakpoint) { // Responsive  : mobile screen
            display: none;
        }
        
    }
    .sans-barre{
        border:none;
    }

    &-burger{
        cursor: pointer;
        &:hover{
            color:$swift-grass;
        }
    }

    &-menu-popover{
        background-color: $swift-lime-1;
       padding:2px;
       display:flex;
       flex-direction: column;
       align-items: flex-end;
       a{
           display:block;
           width:180px;
           padding:8px;
           text-align: right;
           font-size: 18px;
           margin:1px;
           text-transform: capitalize;
           border-bottom: 1px solid;
           color:$swift-coal-2;
           &:hover{
               font-weight: 500;
               background-color: $swift-lime-2;
           }

           &:nth-child(1),&:nth-child(3),&:nth-child(4),&:nth-child(7){
            color:$swift-coal-1;
        }
           
         
       }
    }
    &-root-popover{
        margin-top:18px;
        margin-left:16px;
    }
}