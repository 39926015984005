.top-nav {
  background-color: #F0EFEA;
  /*  position: sticky;
   top:0px;
   z-index:100; */
  max-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #687777;
}
.top-nav-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.top-nav-left a {
  border: none !important;
}
.top-nav-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.top-nav a {
  font-size: 20px;
  color: #394848;
  margin-right: 16px;
  padding-right: 16px;
  display: block;
  border-right: 2px solid #2B9E9C;
  text-transform: capitalize;
}
.top-nav a:hover {
  color: #3CACAB;
}
@media (max-width: 880px) {
  .top-nav a {
    display: none;
  }
}
.top-nav .sans-barre {
  border: none;
}
.top-nav-burger {
  cursor: pointer;
}
.top-nav-burger:hover {
  color: #2B9E9C;
}
.top-nav-menu-popover {
  background-color: #F0EFEA;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.top-nav-menu-popover a {
  display: block;
  width: 180px;
  padding: 8px;
  text-align: right;
  font-size: 18px;
  margin: 1px;
  text-transform: capitalize;
  border-bottom: 1px solid;
  color: #394848;
}
.top-nav-menu-popover a:hover {
  font-weight: 500;
  background-color: #BADE9A;
}
.top-nav-menu-popover a:nth-child(1), .top-nav-menu-popover a:nth-child(3), .top-nav-menu-popover a:nth-child(4), .top-nav-menu-popover a:nth-child(7) {
  color: #687777;
}
.top-nav-root-popover {
  margin-top: 18px;
  margin-left: 16px;
}

.intro {
  background-color: #BADE9A;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}
.intro-top {
  padding: 16px;
  position: relative;
}
.intro-top-underline {
  position: absolute;
  right: 0px;
  top: 50px;
  height: 2px;
  width: 250px;
  background-color: #F0EFEA;
}
.intro-top h1 {
  font-family: "Chakra Petch";
  font-weight: 700;
  font-size: 32px;
  color: #394848;
  text-align: center;
  line-height: 32px;
}
@media (max-width: 880px) {
  .intro-top h1 {
    font-size: 22px;
  }
}
.intro-top-txt1 {
  font-family: "Rajdhani";
  font-weight: 400;
  font-size: 24px;
  margin-right: 8px;
  font-size: 32px;
}
@media (max-width: 880px) {
  .intro-top-txt1 {
    font-size: 20px;
  }
}
.intro-top-txt2 {
  font-family: "Rajdhani";
  font-size: 24px;
  color: #2B9E9C;
}
@media (max-width: 880px) {
  .intro-top-txt2 {
    font-size: 20px;
    font-weight: 500;
  }
}
.intro-middle img {
  border-radius: 50%;
  height: 220px;
}
.intro-bottom {
  margin: 24px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
@media (max-width: 880px) {
  .intro-bottom {
    width: 100%;
  }
}
.intro-bottom img {
  max-height: 36px;
}
@media (max-width: 880px) {
  .intro-bottom img {
    max-height: 26px;
  }
}

.realisations {
  background-color: #F0EFEA;
}
.realisations-content {
  margin-top: 32px;
}
.realisations-selector {
  width: 100%;
  background-color: #3CACAB;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}
@media (max-width: 880px) {
  .realisations-selector {
    flex-direction: column;
  }
}
.realisations-selector p {
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
}
.realisations-selector-categorie, .realisations-selector-categorie-selected {
  padding: 4px 16px;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 880px) {
  .realisations-selector-categorie, .realisations-selector-categorie-selected {
    width: 100%;
    margin-top: 4px;
  }
}
.realisations-selector-categorie {
  color: #F0EFEA;
  border-bottom: 1px solid #F0EFEA;
}
.realisations-selector-categorie:hover {
  border-bottom: 3px solid #F0EFEA;
  padding-bottom: 1px;
}
.realisations-selector-categorie-selected {
  background-color: #F0EFEA;
  border-radius: 10px;
  font-weight: 700;
  color: #394848;
}
.realisations-list {
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 880px) {
  .realisations-list {
    justify-content: space-around;
  }
}
.realisations-seemore {
  width: 100%;
  margin-top: 16px;
  padding: 8px;
  border-top: 2px solid #2B9E9C;
  text-align: center;
}
.realisations-seemore p {
  display: block;
  padding: 8px;
  font-size: 20px;
  color: #2B9E9C;
  cursor: pointer;
}
.realisations-seemore p:hover {
  color: #394848;
}
.realisations-dialog-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 880px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
}
@media (max-width: 880px) {
  .realisations-dialog-content {
    width: 90%;
  }
}
.realisations-dialog-content-focusimage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
}
.realisations-dialog-content-focusimage img {
  width: 100%;
  object-fit: cover;
}
.realisations-dialog-top {
  width: 100%;
  position: relative;
}
.realisations-dialog-top img {
  width: calc(100% - 8px);
  height: 200px;
  margin: 4px;
  object-fit: cover;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.realisations-dialog-top span {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: white;
  border-radius: 50%;
  top: 16px;
  right: 16px;
  color: #3CACAB;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.15));
}
.realisations-dialog-top span:hover {
  color: #394848;
}
.realisations-dialog-top-fondu {
  position: absolute;
  width: 100%;
  top: 0px;
  height: 50%;
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0));
}
.realisations-dialog-middle {
  margin: 16px auto;
  padding: 16px;
  border-top: 2px solid #2B9E9C;
  width: calc(100% - 8px);
  text-align: center;
}
.realisations-dialog-middle h4 {
  font-family: "Chakra Petch";
  font-size: 24px;
  color: #2B9E9C;
}
.realisations-dialog-middle h5 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
}
.realisations-dialog-middle-infos {
  width: 100%;
}
@media (max-width: 880px) {
  .realisations-dialog-middle-infos {
    width: 95%;
  }
}
.realisations-dialog-middle-infos-1 {
  font-weight: 600;
}
.realisations-dialog-middle-infos div {
  border-left: 2px solid #687777;
  margin-top: 16px;
  padding: 8px;
  text-align: left;
}
.realisations-dialog-middle-infos p {
  margin-top: 8px;
  color: #394848;
}
.realisations-dialog-middle-infos p span {
  text-decoration: underline;
}
.realisations-dialog-middle-infos a span {
  color: black;
  font-weight: 600;
  overflow-wrap: break-word;
}
.realisations-dialog-bottom {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.realisations-dialog-bottom button {
  margin-top: 16px;
  color: #2B9E9C;
}
.realisations-dialog-bottom-expand {
  background-color: #2B9E9C !important;
  color: #F0EFEA !important;
  width: calc(100% - 8px) !important;
  margin: 8px auto !important;
  display: flex;
}
.realisations-dialog-bottom-expand:hover {
  background-color: #687777 !important;
  color: #BADE9A !important;
}
.realisations-dialog-bottom-photos {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  background-color: #394848;
  padding: 8px;
}
.realisations-dialog-bottom-photos img {
  width: 100%;
  /* height:100px;
  object-fit: cover; */
  margin: 16px 4px;
  cursor: pointer;
  border: 2px solid #687777;
  border-radius: 4px;
}
@media (max-width: 880px) {
  .realisations-dialog-bottom-photos img {
    width: 90%;
  }
}

.card-realisation {
  width: 350px;
  height: 350px;
  border-radius: 4px;
  margin: 16px 8px;
  background-color: white;
  filter: drop-shadow(2px 5px 2px rgba(0, 0, 0, 0.15));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-realisation-top {
  height: 160px;
}
.card-realisation-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.card-realisation-middle {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}
.card-realisation-middle-start h3 {
  font-weight: 700;
  font-size: 20px;
  font-family: "Chakra Petch";
  text-align: center;
  color: #394848;
}
.card-realisation-middle-end p {
  margin-bottom: 2px;
}
.card-realisation-middle-categorie {
  width: 100%;
  text-align: center;
  border-top: 1px solid #3CACAB;
  padding: 4px;
}
.card-realisation-middle-categorie p {
  color: #2B9E9C;
  font-family: "Chakra Petch";
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-realisation-description {
  color: #394848;
  font-weight: 500;
}
.card-realisation-tags {
  color: #687777;
}
.card-realisation-bottom {
  padding: 4px;
}
.card-realisation-bottom button {
  background-color: #2B9E9C !important;
  font-family: "Rajdhani" !important;
}
.card-realisation .categorie-couleur-1 {
  color: #2B9E9C;
}
.card-realisation .categorie-couleur-2 {
  color: #7458e8;
}
.card-realisation .categorie-couleur-3 {
  color: rgb(197, 105, 0);
}

.competences {
  background-color: #3CACAB;
}
.competences-content {
  padding: 32px 16px;
}
.competences-content li {
  margin-top: 8px;
}
@media (max-width: 880px) {
  .competences-content {
    padding: 16px 8px;
  }
}
.competences-content-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 880px) {
  .competences-content-item-header {
    flex-wrap: wrap;
  }
  .competences-content-item-header h3 {
    font-size: 16px;
    width: 100%;
  }
}
.competences-content-item-header h3 {
  font-size: 18px;
  font-weight: 500;
}
.competences-content-item-realisations {
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #F0EFEA;
  border-top: 2px solid #2B9E9C;
  width: 90%;
  margin: auto;
}
.competences-content-item-realisations-titre {
  text-transform: uppercase;
  font-size: 16px;
  color: #F0EFEA;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #2B9E9C;
  border-radius: 4px;
  padding: 4px 8px;
}
@media (max-width: 880px) {
  .competences-content-item-realisations-titre {
    flex-direction: column;
  }
  .competences-content-item-realisations-titre span {
    margin-left: 4px;
    font-size: 14px;
  }
}
.competences-content-item-realisations-titre span {
  color: white;
  display: flex;
  align-items: center;
  margin-left: 16px;
}
.competences-content-item-realisations-titre:hover {
  color: #F0EFEA;
  background-color: #394848;
}
.competences-content-item-realisations-ligne {
  width: 100%;
  background-color: white;
  color: #394848;
  padding: 4px 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
  border-bottom: 1px solid #BADE9A;
}
.competences-content-item-realisations-ligne:hover {
  background-color: #F0EFEA;
}
.competences-content-item-realisations-ligne p :nth-child(1) {
  color: black;
  font-weight: 500;
}
.competences-content-item-experience-ligne {
  width: 100%;
  background-color: #687777;
  color: #F0EFEA;
  padding: 4px 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  border-bottom: 1px solid #BADE9A;
}
.competences-content-item-experience-ligne span {
  margin-right: 6px;
}
.competences-content-item-experience-ligne span:nth-child(1) {
  color: #BADE9A !important;
}
.competences-content-item-experience-ligne span:nth-child(7) {
  color: #9FE5E4 !important;
}
.competences-soft {
  background-color: #394848;
}
.competences-soft-top h3 {
  font-family: "Chakra Petch";
  font-size: 24px;
  color: #9FE5E4;
  text-align: center;
  font-weight: 300;
  margin-bottom: 16px;
}
.competences-soft-top p {
  color: #F0EFEA;
  text-align: justify;
}
.competences-soft-top-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
}
.competences-soft-top-skill {
  border-bottom: 1px solid #F0EFEA;
  padding: 8px 32px;
  text-align: center;
  width: 47%;
  margin: 8px;
  cursor: pointer;
}
@media (max-width: 880px) {
  .competences-soft-top-skill {
    width: 98%;
    padding: 8px;
  }
}
.competences-soft-top-skill:hover {
  border-color: #BADE9A;
}
.competences-soft-top-skill h4 {
  text-transform: uppercase;
  color: #F0EFEA;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.competences-soft-top-skill h4 span {
  margin-left: 8px;
}
.competences-soft-top-skill-selected {
  border-bottom: 2px solid #BADE9A;
  font-weight: 700;
}
.competences-soft-top-skill-selected h4 {
  color: #BADE9A;
}
.competences-soft-bottom-titre {
  width: 100%;
  text-align: center;
  background-color: #BADE9A;
  border-radius: 6px;
  padding: 8px;
  margin-top: 32px;
  position: relative;
}
.competences-soft-bottom-titre p {
  color: #394848;
  font-size: 18px;
  font-weight: 500;
}
.competences-soft-bottom-content {
  background-color: #F0EFEA;
  margin-top: 4px;
  border-radius: 4px;
  padding: 8px;
}
.competences-soft-bottom ul {
  color: #394848;
}
.competences-soft-bottom-texte {
  color: #394848;
}
.competences-soft-bottom-barre {
  width: 50%;
  height: 2px;
  display: block;
  background-color: #2B9E9C;
}
.competences-soft-bottom-exemples {
  padding: 16px;
}
.competences-soft-close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 16px;
}

.experiences {
  background-color: #BADE9A;
}
.experiences-top {
  margin-top: 16px;
}
.experiences-top p {
  text-align: justify;
}
.experiences-content {
  padding-bottom: 32px;
}
.experiences-content-caroussel {
  padding: 16px 0px;
}
.experiences-ref {
  background-color: #687777;
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 880px) {
  .experiences-ref {
    padding: 16px;
  }
}
.experiences-ref h3 {
  color: #BADE9A;
  font-family: "Chakra Petch";
  font-weight: 300;
  font-size: 22px;
}
.experiences-ref-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
}
@media (max-width: 880px) {
  .experiences-ref-logos {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.experiences-ref-logos img {
  height: 150px;
  width: 150px;
  margin: 4px;
}
@media (max-width: 880px) {
  .experiences-ref-logos img {
    height: 80px;
    width: 80px;
  }
}
.experiences-ref-reco {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  padding: 32px;
  margin-bottom: 32px;
}
.experiences-ref-reco img {
  width: 60%;
  max-width: 500px;
}
@media (max-width: 880px) {
  .experiences-ref-reco img {
    width: 80%;
    max-width: none;
  }
}
.experiences-ref ul {
  color: #F0EFEA;
}
.experiences-ref ul a {
  text-decoration: underline;
}

.card-experience {
  background-color: #F0EFEA;
  width: 90%;
  max-width: 500px;
  height: 500px;
  margin: auto;
  border-radius: 8px;
  filter: drop-shadow(2px 5px 2px rgba(0, 0, 0, 0.15));
  margin: 32px auto;
  display: flex;
  flex-direction: column;
}
.card-experience-top {
  text-align: center;
}
.card-experience-top img {
  width: calc(100% - 8px);
  height: 150px;
  margin: 4px auto;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.card-experience-top-frise {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 16px;
  align-items: center;
}
.card-experience-top-frise span {
  background: linear-gradient(90deg, #687777, #2B9E9C);
  height: 2px;
  flex-grow: 1;
}
.card-experience-top-frise p {
  color: #2B9E9C;
  padding: 2px 8px;
}
.card-experience-date-start {
  color: #687777 !important;
}
.card-experience-middle {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-experience-middle h3 {
  font-family: "Chakra Petch";
  font-weight: 400;
  font-size: 22px;
  color: #2B9E9C;
}
.card-experience-middle-contrat {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-experience-middle-contrat-type {
  color: #687777;
  margin-left: 4px;
}
.card-experience-middle-contrat-employeur {
  color: #394848;
  font-weight: 500;
  margin-right: 4px;
}
.card-experience-middle-location {
  margin-top: 8px;
  padding: 4px 32px;
  display: flex;
  align-items: center;
  border-top: 1px solid #BADE9A;
}
.card-experience-middle-location p {
  margin: 4px;
  color: #687777;
  font-size: 14px;
}
.card-experience-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.card-experience-bottom-infos {
  width: calc(100% - 16px);
  margin: auto;
  border-top: 1px solid #2B9E9C;
  padding: 16px;
}
.card-experience-bottom-infos p {
  color: #394848;
  text-align: justify;
}
.card-experience-bottom-softs {
  color: #687777;
  font-size: 14px;
  padding: 8px 16px;
}
.card-experience-bottom-softs p {
  display: flex;
  align-items: center;
}
.card-experience-bottom-softs p span {
  margin-right: 8px;
}

.diplomes {
  background-color: #9FE5E4;
}
.diplomes-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 32px;
}
@media (max-width: 880px) {
  .diplomes-top {
    flex-direction: column;
    padding: 32px;
    margin-top: 8px;
  }
}
.diplomes-top-diplome {
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 4px;
}
@media (max-width: 880px) {
  .diplomes-top-diplome {
    margin: 8px;
  }
}
.diplomes-top-diplome figure {
  border: 1px solid #BADE9A;
  width: 80px;
  height: 80px;
  padding: 8px;
  border-radius: 50%;
  text-align: center;
}
.diplomes-top-diplome h3 {
  color: #2B9E9C;
  font-size: 24px;
}
.diplomes-top-diplome h4 {
  color: #394848;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.diplomes-top-icone {
  color: #3CACAB;
  font-size: 60px !important;
}

.apropos {
  background-color: #3CACAB;
}
.apropos p {
  color: white;
}
.apropos-top {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media (max-width: 880px) {
  .apropos-top {
    flex-direction: column;
    align-items: center;
  }
}
.apropos-top-left {
  padding: 8px;
}
.apropos-top-left img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.apropos-top-right {
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: justify;
}
.apropos-top-right-links {
  display: flex;
  flex-direction: column;
}
@media (max-width: 880px) {
  .apropos-top-right-links {
    margin-top: 16px;
  }
}
.apropos-top-right a {
  color: #F0EFEA;
  font-weight: 500;
  margin: 2px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.apropos-top-right a span {
  margin-left: 8px;
}
.apropos-middle {
  margin-top: 16px;
  text-align: justify;
}

.contacts {
  background-color: #394848;
}
.contacts-top {
  margin: 16px auto;
  padding-bottom: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 880px) {
  .contacts-top {
    flex-direction: column;
    align-items: left;
  }
}
.contacts-top-left p {
  font-size: 18px;
  color: #F0EFEA;
  margin: 8px;
}
.contacts-top-right {
  text-align: right;
}
@media (max-width: 880px) {
  .contacts-top-right {
    margin-top: 16px;
    text-align: left;
  }
}
.contacts-top-right p {
  font-size: 18px;
  color: #F0EFEA;
  margin: 8px;
}

.recommandations {
  background-color: #F0EFEA;
}
.recommandations-content {
  padding: 16px;
  max-width: 960px;
  margin: auto;
}
@media (max-width: 880px) {
  .recommandations-content {
    padding: 16px;
  }
}
.recommandations-content p {
  text-align: right;
}
.recommandations-content p span {
  font-weight: 300;
}
.recommandations-content blockquote {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  padding: 16px;
  margin: 4px;
  font-family: "Handlee", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 0.03em;
  text-align: justify;
  white-space: pre-line;
}
@media (max-width: 880px) {
  .recommandations-content blockquote {
    font-size: 14px;
  }
}

/****** General ***********/
* {
  font-family: "Rajdhani", sans-serif;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

p, pre, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

body {
  background-color: white;
  height: 100%;
  margin: 0;
}

header {
  position: sticky;
  top: 0px;
  z-index: 100;
}

.section-container {
  padding: 64px;
  max-width: 960px;
  margin: auto;
}
@media (max-width: 880px) {
  .section-container {
    padding: 16px;
  }
}

.aeration-basse {
  height: 128px;
}

.section-title h2 {
  padding-bottom: 4px;
  font-size: 32px;
  font-weight: 300;
  font-family: "Chakra Petch";
  text-transform: capitalize;
}
@media (max-width: 880px) {
  .section-title h2 {
    font-size: 22px;
  }
}
.section-title span {
  display: block;
  width: 50%;
  height: 3px;
}
.section-title-text-swift-lime-1 {
  color: #F0EFEA;
}
.section-title-text-swift-lime-2 {
  color: #BADE9A;
}
.section-title-text-swift-coal-1 {
  color: #687777;
}
.section-title-text-swift-coal-2 {
  color: #394848;
}
.section-title-text-swift-turquoise {
  color: #3CACAB;
}
.section-title-text-swift-grass {
  color: #2B9E9C;
}
.section-title-text-swift-freeze {
  color: #9FE5E4;
}
.section-title-border-swift-lime-1 {
  background-color: #F0EFEA;
}
.section-title-border-swift-lime-2 {
  background-color: #BADE9A;
}
.section-title-border-swift-coal-1 {
  background-color: #687777;
}
.section-title-border-swift-coal-2 {
  background-color: #394848;
}
.section-title-border-swift-turquoise {
  background-color: #3CACAB;
}
.section-title-border-swift-grass {
  background-color: #2B9E9C;
}
.section-title-border-swift-freeze {
  background-color: #9FE5E4;
}

